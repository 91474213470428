import axios from 'axios';

import { userConstants } from '../../../constants/userConst';

const loginUserBegin = () => ({
  type: userConstants.LOGIN_BEGIN,
});

const loginUserSuccess = userData => ({
  type: userConstants.LOGIN_SUCCESS,
  userData,
});

const loginUserFailure = () => ({
  type: userConstants.LOGIN_FAILURE,
});

export const loginUser = credentials => async dispatch => {
  dispatch(loginUserBegin());
  try {
    const { data } = await axios.post('user/auth/', credentials);
    dispatch(loginUserSuccess());
    return data.access;
  } catch {
    dispatch(loginUserFailure());
    return null;
  }
};

const logoutUserSuccess = () => ({
  type: userConstants.LOGOUT,
});

export const logoutUser = () => dispatch => {
  dispatch(logoutUserSuccess);
};
