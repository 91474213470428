import './Button.css';

import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import { variants } from './Button.variants';

const Button = ({ children, className, variant, ...legacyProps }) => (
  <button className={classNames(className, variant)} {...legacyProps}>
    {children}
  </button>
);

Button.defaultProps = {
  variant: variants.primary,
};

Button.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

export default Button;
