import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { userReducer } from './reducers/userReducer';

export const configureStore = () => {
  let enhancer = compose(applyMiddleware(thunk));

  if (process.env.NODE_ENV !== 'production') {
    enhancer = compose(
      applyMiddleware(thunk, logger),
      window.navigator.userAgent.includes('Chrome')
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        : compose,
    );
  }

  return createStore(userReducer, enhancer);
};
