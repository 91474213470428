import React from 'react';

import Button, { ButtonVariants } from '../../components/Button';
import MainContainer from '../../containers/MainContainer';
import { theme } from '../../styles/theme';

const ChooseAccountView = () => (
  <MainContainer>
    <h1>Choose account</h1>
    <span>Choose Google Ads account</span>
    <div style={{ width: '65%', marginTop: '3%' }}>
      <select
        style={{
          width: '100%',
          height: '96px',
          border: `1px solid ${theme.primary100}`,
        }}>
        <option> Castorama account 1 </option>
        <option> Castorama account 2 </option>
        <option> Castorama account 3 </option>
      </select>
      <div style={{ display: 'flex', margin: '2% 0' }}>
        <Button style={{ width: '100%' }} variant={ButtonVariants.secondary}>
          Import structure from Google Ads
        </Button>
        <Button
          style={{ width: '100%', marginLeft: '2%' }}
          variant={ButtonVariants.secondary}>
          Pick saved structure
        </Button>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        height: '45%',
      }}>
      <Button>Next</Button>
    </div>
  </MainContainer>
);

export default ChooseAccountView;
