import './App.css';
import './styles/global.css';

import MainRouter from './routers/MainRouter/';
import { configureAxiosDefaults } from './utils';

configureAxiosDefaults();

function App() {
  return <MainRouter />;
}

export default App;
