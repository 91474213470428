import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute';
import StructuresContainer from '../../containers/StructuresContainer';
import { ChangePasswordView, ChooseAccount, LoginView } from '../../views';

const MainRouter = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute path="/account" component={ChooseAccount} />
      <Route path="/structure">
        <StructuresContainer />
      </Route>
      <PrivateRoute path="/changepassword" component={ChangePasswordView} />
      <Route path={['/login', '/']}>
        <LoginView />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default MainRouter;
