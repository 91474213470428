import './MainContainer.css';

import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonVariants } from '../../components/Button';
import BackgroundPic from '../../media/png/main_view_bckgrnd.png';
import { ReactComponent as CastoramaLogo } from '../../media/svg/castorama_logo.svg';

const MainContainer = ({ children }) => {
  const btnNamesList = ['Sitelinks', 'SKU', 'CSV'];

  return (
    <div className="main-container">
      <div className="main-left-side-container">
        <div className="main-container-header">
          <CastoramaLogo />
        </div>
        <div className="main-content-container">{children}</div>
      </div>
      <div
        className="main-right-side-container"
        style={{ backgroundImage: `url(${BackgroundPic})` }}>
        <div className="main-btns-container">
          {btnNamesList.map(btnName => (
            <Button
              className={'docs-link-btns'}
              key={btnName}
              variant={ButtonVariants.secondary}>
              {btnName}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
MainContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
export default MainContainer;
