import './ChangePassword.css';

import React from 'react';

import Button from '../../components/Button';
import MainContainer from '../../containers/MainContainer';
import { ReactComponent as Arrow } from '../../media/svg/icons/arrow.svg';
import { useChangePassword } from './ChangePassword.hook';

const ChangePasswordView = () => {
  const {
    changePassword,
    error,
    passwordChanged,
    labelClassName,
    history,
    redirectTime,
  } = useChangePassword();

  if (passwordChanged) {
    return (
      <MainContainer>
        <div className="reseted-password-info-container">
          <h1>Password has been changed!</h1>
          <span className="span-information-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            dignissim
            <br />
            phasellus pellentesque morbi. Malesuada eu dolor malesuada in nunc.
          </span>
          <span className="redirect-time-text">
            Automatically redirecting in {redirectTime}
          </span>
        </div>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <div className="reset-password-container">
        <button className="go-back-btn" onClick={() => history.push('/')}>
          <Arrow />
        </button>
        <form onSubmit={changePassword}>
          <div className="reset-text-container">
            <h1>Change password</h1>
            <span className="span-information-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              dignissim
              <br />
              phasellus pellentesque morbi. Malesuada eu dolor malesuada in
              nunc.
            </span>
            <label className={labelClassName}>
              Current password
              <input
                className="reset-password-input"
                name="currentPassword"
                placeholder="Enter current password"
                type="password"
              />
            </label>
            <label className={labelClassName}>
              New Password
              <input
                className="reset-password-input"
                name="newPassword"
                placeholder="Enter new password"
                type="password"
              />
            </label>
            <label className={labelClassName}>
              Repeat new password
              <input
                className="reset-password-input"
                name="repeatPassword"
                placeholder="Enter new password again"
                type="password"
              />
            </label>
            {error && (
              <span className="error-msg">
                Oh. There was something wrong :(
              </span>
            )}
            <Button className="reset-btn" type="submit">
              Change
            </Button>
          </div>
        </form>
      </div>
    </MainContainer>
  );
};

export default ChangePasswordView;
