import { Map } from 'immutable';

import { userConstants } from '../constants/userConst';

const initialState = Map({
  user: Map({
    loggedIn: false,
    userData: null,
  }),
});

const userLoginSuccess = (state, { userData }) =>
  state.set(
    ['user'],
    Map({
      loggedIn: true,
      userData: userData,
    }),
  );

const userLogout = state =>
  state.set(
    ['user'],
    Map({
      loggedIn: false,
      userData: null,
    }),
  );

export const userReducer = (state = initialState, action) =>
  ((
    {
      [userConstants.LOGIN_SUCCESS]: userLoginSuccess,
      [userConstants.LOGOUT]: userLogout,
    }[action.type] || (fn => fn)
  )(state, action));
