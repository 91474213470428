import './LoginView.css';

import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../components/Button';
import { regexes } from '../../constant/regexes';
import MainContainer from '../../containers/MainContainer';
import { loginUser } from '../../store/actions/userActions/login';

const LoginView = () => {
  let history = useHistory();
  const [error, setError] = useState(null);
  const [isInputValid, setIsInputValid] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('jwt')) {
      history.push('/account');
    }
  });

  const signIn = async event => {
    event.preventDefault();
    const token = await dispatch(
      loginUser({
        email: event.target.email.value,
        password: event.target.password.value,
      }),
    );

    if (!token) return setError(true);

    localStorage.setItem('jwt', token);
    history.push('/account');
  };

  const validateEmail = event => {
    setIsInputValid(regexes.emailRegex.test(event.target.value));
  };

  return (
    <MainContainer>
      <div className="login-text-container">
        <h1>Login</h1>
        <span className="span-information-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
          dignissim <br />
          phasellus pellentesque morbi. Malesuada eu dolor malesuada in nunc.
        </span>
      </div>
      <form onSubmit={signIn}>
        <div className="login-inputs-container">
          <label className="login-inputs-labels">
            E-mail
            <input
              className={classNames({
                'invalid-input': !isInputValid,
                'login-input': isInputValid,
              })}
              placeholder="Insert your e-mail"
              name="email"
              required
              onChange={validateEmail}
            />
          </label>
          <label className="login-inputs-labels">
            Password
            <input
              className="login-input"
              name="password"
              placeholder="Insert your password"
              type="password"
              required
            />
          </label>
          {error && (
            <span className="error-msg">Oh. There was something wrong :(</span>
          )}
        </div>
        <div className="login-btns-container">
          <Button type="submit" className="login-btn">
            Login
          </Button>
        </div>
      </form>
    </MainContainer>
  );
};

export default LoginView;
