export const theme = {
  primary20: '#013159',
  primary40: '#014D8C',
  primary60: '#0278DA',
  primary80: '#80C5FF',
  primary100: '#D9EEFF',
  /* Neutrals */
  neutral00: '#222C3B',
  neutral20: '#425673',
  neutral40: '#B1BBCA',
  neutral60: '#DAE4F2',
  neutral80: '#E7F1FA',
  neutral90: '#F0F7FD',
  /* Success */
  green20: '#116E10',
  green40: '#218318',
  green60: '#359921',
  green80: '#8FE573',
  green100: '#D4FFBF',
  /* Error */
  error20: '#B71121',
  error40: '#DB191C',
  error60: '#FF3223',
  error80: '#FF7259',
  error100: '#FF997A',
  /* Warning */
  warning20: '#B76B03',
  warning40: '#DB8805',
  warning60: '#FFA807',
  warning80: '#FFC445',
  warning100: '#FFE8AC',
  /* Information */
  information20: '#182BB1',
  information40: '#233BD4',
  information60: '#314FF7',
  information80: '#637CFA',
  information100: '#D6DDFC',

  /* Other */
  white: '#fff',
};
