import axios from 'axios';

const hasJwt = () => !!window.localStorage.getItem('jwt');

const setAuthorization = () => {
  if (!hasJwt()) {
    return delete axios.defaults.headers.common.Authorization;
  }

  const token = window.localStorage.getItem('jwt');
  axios.defaults.headers.common.Authorization = `JWT ${token}`;
  return;
};

export function configureAxiosDefaults() {
  setAuthorization();
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
}
